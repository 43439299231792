import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

const variantMapping = {
  default: '#fff',
  success: '#8db319',
  primary: '#284fff',
  danger: '#ff495c',
}

const Button = ({
  className = '',
  href,
  target,
  onClick,
  children,
  type = 'button',
  disabled,
}) => {
  let theClassNames = `btn ${className}`
  if (disabled) {
    theClassNames += ' disabled'
  }
  return href ? (
    <a className={theClassNames} href={href} target={target}>
      {children}
    </a>
  ) : (
    <button
      className={theClassNames}
      type={type}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  )
}

const StyledButton = styled(Button)`
  font-size: 14px;
  font-family: 'Fira Sans', 'Lucida Grande', 'Trebuchet MS', sans-serif;
  padding: 6px 12px;
  margin-bottom: 0;
  border-radius: 4px;

  display: inline-block;
  text-decoration: none;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid rgba(0, 0, 0, 0.15);

  &:focus,
  &:active:focus {
    outline: thin dotted;
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
  }

  &:hover {
    filter: brightness(90%);
  }

  &:hover,
  &:focus {
    text-decoration: none;
  }

  &:active,
  &.active {
    background-image: none;
    outline: 0;
    -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  }

  color: ${({ variant }) => {
    return !variant || variant === 'default'
      ? '#333 !important'
      : '#fff !important'
  }};
  background-color: ${({ variant }) => {
    return variantMapping[variant] || '#fff'
  }};

  .btn + & {
    margin-left: 10px;
  }

  &:disabled,
  &.disabled,
  fieldset:disabled & {
    pointer-events: none;
    opacity: 0.65;
    box-shadow: none;
  }
`

Button.propTypes = {
  href: PropTypes.string,
  target: PropTypes.string,
  onClick: PropTypes.func,
  type: PropTypes.string,
  // variant is indeed used ... just in a styled component
  variant: PropTypes.oneOf(['default', 'success', 'danger', 'primary']), // eslint-disable-line
}

export default StyledButton
