import React from 'react'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import SEO from '../components/SEO'
import Nav from '../components/Nav'
import Content from '../components/Content'
import Button from '../components/Button'

import DocStyles from '../styles/doc.module.scss'

const BiggerButton = styled(Button)`
  min-width: 240px;
`

const NotFoundPage = ({ location }) => {
  return (
    <>
      <Nav location={location} />
      <div className={DocStyles.DocWrapper}>
        <Content>
          <SEO title="Home" />
          <h1 style={{ textTransform: 'uppercase' }}>
            4<span style={{ textTransform: 'lowercase' }}>o</span>4 - Thing Not
            Found
          </h1>
          <p>
            We're happy that you're here – not <strong>here</strong> at this
            page exactly, but here at Losant's documentation. So let's get you
            pointed in the right direction.
          </p>
          <p>
            You can search the documentation to find something specific, or you
            can read an overview of the{' '}
            <a href="/getting-started/what-is-losant/">
              Losant Enterprise IoT Platform
            </a>
            . Perhaps you want to read about some Losant{' '}
            <a href="/getting-started/tutorials/">tutorials and projects</a>, or
            you want to run through the{' '}
            <a href="/getting-started/walkthrough/">Losant Walkthrough</a>.
          </p>
          <p>
            Of course, you can always go start working with the Losant platform
            directly! Click the links below to start building for free today.
          </p>
          <p style={{ textAlign: 'center' }}>
            <BiggerButton
              href="https://accounts.losant.com/create-account?utm_campaign=Docs&amp;utm_source=Docs"
              target="_blank"
              variant="primary"
            >
              Create An Account
            </BiggerButton>
          </p>
          <p style={{ textAlign: 'center' }}>
            <small style={{ display: 'block' }}>Already have an account?</small>
            <BiggerButton
              href="https://accounts.losant.com/signin?utm_campaign=Docs&amp;utm_source=Docs"
              target="_blank"
              variant="default"
            >
              Sign In
            </BiggerButton>
          </p>
        </Content>
      </div>
    </>
  )
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
